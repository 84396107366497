import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';

const WhatIsThis = ({ location }) => {
  const pageTitle = 'What Is Rad Devon?';
  return (
    <Layout pageTitle={pageTitle}>
      <Seo pageTitle={pageTitle} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">{pageTitle}</h1>
        <p>
          You aren’t happy with your job. You’d like something better, but{' '}
          <strong>you feel like you don’t have much control</strong>. You see
          people doing amazing, fulfilling work. You want to be like them, but
          you know they’re somehow different from you — smarter, luckier, better
          connections…
        </p>
        <StaticImage
          src="./images/devon-wall-a.jpg"
          alt="Devon wearing a black shirt and black glasses, standing against a gray retaining wall, smiling at the camera"
          className="f-right"
          imgStyle={{ marginLeft: '1em' }}
        />
        <p>
          This is how I thought about my career until very recently. Now,{' '}
          <strong>I earn six figures 💰 as a web developer</strong>. I live in
          the city of my dreams (Seattle, in case you’re curious) 🌃, do work
          that excites me 😍, and learn something new every day 🤔. I’m
          healthier 💪 and happier 😌. I have more time to pursue my interests,
          work on my own projects, and spend time with my family.
        </p>
        <p
          style={{
            fontSize: '3rem',
            lineHeight: '1.2',
            marginBottom: '0.2em',
          }}>
          Rad Devon is where I share what I’ve learned so you can do it too. 🤘
        </p>
        <h2>Why Learn Here?</h2>
        <p>
          Here’s what makes RadDevon.com <strong>different</strong>:
        </p>
        <ol>
          <li>
            <strong>It’s not just technical.</strong> Being a web developer is
            not just about learning to write code. You need to be able to{' '}
            <a href="/articles/get-first-freelance-web-development-job/">
              get work
            </a>
            , but, before you can even do that, you need to{' '}
            <a href="/articles/stay-motivated-learning-web-development/">
              find the motivation
            </a>{' '}
            to learn a whole bunch of stuff. It’s an often overlooked part of
            becoming a developer.
          </li>
          <li>
            <strong>Build connections.</strong> When you start getting immersed
            into this whole world of new skills, it’s important to be able to
            understand why things work the way they do and to connect concepts
            to things you already understand in the real world. This makes your
            learning more sticky.
          </li>
          <li>
            <strong>Don’t skip the details.</strong> I could write shorter posts
            and make shorter videos by glossing over the details, but, if you
            follow along, it’s not going to work. Instead, I want to give you
            everything you need from start to finish, even if it takes a little
            longer. I make mountains out of molehills because you’re more likely
            to remember mountains 🏔.
          </li>
          <li>
            <strong>
              No{' '}
              <span
                style={{ textDecoration: 'line-through', color: 'darkred' }}>
                foo
              </span>
              ,{' '}
              <span
                style={{ textDecoration: 'line-through', color: 'darkred' }}>
                bar
              </span>
              , and{' '}
              <span
                style={{ textDecoration: 'line-through', color: 'darkred' }}>
                baz
              </span>{' '}
              in tutorials.
            </strong>{' '}
            The point of learning is to be able to apply it. These abstract
            tutorials don’t get you any closer to understanding how to apply
            what you’ve learned.
          </li>
        </ol>
        <h2>Can I Do This?</h2>
        <p>
          I used to wake up every day dreading my job. I’d go in to work. They’d
          tell me where to stand, exactly what to do, what to say, how to dress,
          and when to go to the bathroom. I couldn’t help thinking,{' '}
          <strong>“Is this what being an adult is?”</strong> It felt just like
          being in school 🏫. When I shared this with people, they told me that
          being an adult is about <strong>doing what you’re told</strong>.
          Everyone hates their job. This is the reality if you want to be{' '}
          <strong>responsible</strong>.
        </p>
        <p>
          Responsibility is certainly part of adulthood, but blind compliance
          doesn’t have to be. Looking back, I realize I was having this
          experience because my contribution wasn’t valued. I was a body filling
          up a space, doing mundane tasks anyone could have done. I wasn’t an
          asset; <strong>I was a liability</strong>. Both my pay and the level
          of respect I was shown reflected that.
        </p>
        <p>
          <strong>
            I believe you can build an incredible life just like I did.
          </strong>{' '}
          I’m not a genius 👨‍🔬. I don’t have a crazy math background 📈. Heck, I
          don’t even have a bachelor’s degree 👨‍🎓. I’m just a regular dude who
          figured out how to control the matrix, just a tiny bit. 😉
        </p>
      </Article>
    </Layout>
  );
};
export default WhatIsThis;
